import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Box, MainHeading } from '../../Styled/Elements'
import { DirectImg, Email, Phone } from '../../Styled/AllImages'
import { TextWithIcon } from '../../Styled/CustomElements'
import LeadForm from '../lead-form/leadForm'
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'

const LeadContact = () => {
    const { t } = useTranslation();
    return (
        <LeadContactFluid>
            <ToastContainer />
            <Box className='requirement'>
                <Container>
                    <Row>
                        <MainHeading className='main-heading' size="32px" color='var(--white)'> {t('Share_Property_Requirement')} </MainHeading>
                    </Row>
                </Container>

            </Box>
            <Container>
                <Row className='lead-content'>
                    <Col lg={8} md={12}>
                        <Box className='design form'>
                            <LeadForm />
                        </Box>
                    </Col>
                    <Col lg={4} md={12}>
                        <Box className='design direct-connection'>
                            <Box className='text-center'>
                                <DirectImg />
                            </Box>
                            <MainHeading className='my-3 heading-connect' > {t('Connect_directly_with_brokers')} </MainHeading>
                            <Box className='d-flex flex-column gap-2'>
                                <TextWithIcon icon={Email} title={t('Submit_your_requirement')} />
                                <TextWithIcon icon={Phone} title={t('Receive_direct')} />
                            </Box>
                        </Box>
                    </Col>
                </Row>
            </Container>

        </LeadContactFluid>
    )
}

export default LeadContact

const LeadContactFluid = styled.section`

margin-top:100px;

& .requirement{
    padding:20px 0;
    height:150px;
    background:var(--DarkText);
}

.design{
    background: var(--whiteColor);
    // border: 1px solid gray;
    border-radius: 10px;
    padding: 30px 20px;
    position: relative;
    bottom: 50px;
    box-shadow: 0px 0px 9px 0px rgba(152,111,31,0.2);
    -webkit-box-shadow: 0px 0px 9px 0px rgba(152,111,31,0.2);
    -moz-box-shadow: 0px 0px 9px 0px rgba(152,111,31,0.2);
}
// .direct-connection{
//     position: sticky;
//     top: 200px;
// }


`