

// import Contact from "../components/sections/contact/Contact"

import LeadContact from "../components/sections/lead-contact/LeadContact"

const Index = () => {
    return (
        <>
            {/* <Contact /> */}
            <LeadContact />
        </>
    )
}

export default Index