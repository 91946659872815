import styled from 'styled-components'


export const HeaderContainer = styled.header`
    background: var(--darkBlue);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    // transition: all 0.5s cubic-bezier(.77,0,.18,1);
    .nav-bar{
        display:flex;  
        align-items: center;
        justify-content: space-between;
    }
    .nav-bar-list{
        display:flex;  
        align-items: center;
        gap: 35px;
    }
    .nav-bar-list a{
        color:var(--whiteColor);
        padding-bottom: 12px;
        position: relative;
        font-size:14px;
        font-family: var(--semibold);
        text-transform: uppercase;
        &:hover, &.active, &:focus{
            background: var(--DarkText);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: var(--semibold);
            &::before, &.active::before{
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                width: 15px;
                height: 3px;
                background: var(--DarkText);
            }
        }
    }
    .logo{
        cursor:pointer;
        svg{
            width:180px;
        }
    }
    &.sticky{
        background: var(--darkBlue);
        box-shadow: 0px 3px 10px #001b2ec7;
        z-index: 200;
    }
    .toggleBtn{
        display: none;
    }
}
`