import styled from "styled-components";

export const LeadFormWrapper = styled.div`

.field-box{
    display: flex;
    flex-direction: column;
    gap:8px;
    // margin:20px 0;
    padding: 20px 0;
}
.input-field,
select{
    padding: 8px 18px;
    border-radius: 8px;
    border: 1px solid #e1e4e8;
    outline: none;
    background: transparent;
    height: 43px;
}
.error-message{
    font-family:var(--arRegular);
    color:red;
    padding:6px 8px;
    border-radius:8px;
    background-color: #ff000014;
}
.loading-btn{
    position: fixed;
    left: 0;
    right: 0;
    width: 50%;
    height: 50%;
    z-index: 10;
    background: #0b0b0b6b;
    text-align: center;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color:var(--whiteColor);
    font-family:var(--regular);

}
.contact-social-links{
    position: relative;
    left: 15px;
}

.common-space {
    margin: 10px 0;
}




`